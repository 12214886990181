import React from "react"
import Image from 'gatsby-image'
import Layout from "../components/layout"
import { graphql } from "gatsby"

export default({ data }) => {
  const node = data.allMarkdownRemark.edges[0].node;
  const image = node.frontmatter.featuredImage.childImageSharp.fluid
  return (
    <Layout>
      <div>
        <Image fluid={image}/>
        <h1 style={{textAlign: "center"}}>
          {node.frontmatter.title}
        </h1>
        <div
          dangerouslySetInnerHTML={{__html: node.html }}
          style={{marginBottom: '10em'}}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($nodeId: String!) {
    allMarkdownRemark(filter: {id: {eq: $nodeId}}) {
      edges {
         node {
          id
          html
          frontmatter {
            title
            date
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`